<template>
  <div>
    <v-card>
      <v-card color="primary" min-height="10" flat tile class="pa-1">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn
            @click="closePanel"
            x-small
            fab
            depressed
            color="black"
            dark
            id="btn-close"
            class="mr-1"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <div class="pa-4">
        <v-card-title>
          <span class="font-weight-regular">
            <v-icon class="mr-2">mdi-domain</v-icon>Grupo de regla
          </span>
          <v-spacer />
          <span class="primary--text">
            {{ object.ruleGroupName }}
          </span>
        </v-card-title>
        <v-card-subtitle>
          {{ object.ruleGroupDescription }}
        </v-card-subtitle>
        <v-card-text class="pa-6" v-if="object">
          <v-row align="stretch" justify="start">
            <v-col cols="12" sm="12" md="6">
              <span class="body-1">Nivel de prioridad</span>
              <v-chip
                :color="getPriorityColor(object.ruleGroupPriority)"
                class="ml-2"
              >
                {{ object.ruleGroupPriority }}
              </v-chip>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <span class="body-1">Seller</span>
              <span class="ml-2 font-weight-bold">
                {{ sellers[object.sellerId] || object.sellerId }}
              </span>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <span class="body-1">Consumer</span>
              <span class="ml-2 font-weight-bold">
                {{ consumers[object.consumerId] || object.consumerId }}
              </span>
            </v-col>
          </v-row>
          <v-card flat outlined class="my-6 pa-4">
            <v-card-title class="body-1">Reglas asociadas</v-card-title>
            <v-row
              align="stretch"
              justify="start"
              class="pl-6"
              v-if="rules && rules.length > 0"
            >
              <v-col
                cols="12"
                sm="6"
                md="3"
                v-for="(rule, i) in rules"
                :key="i"
              >
                <v-menu :close-on-content-click="false" :nudge-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      :id="`chip-${rule.ruleCode}`"
                      color="primary darken-2"
                      class="elevation-1"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ rule.ruleCode }}
                    </v-chip>
                  </template>

                  <rule-modal :rule="rule" />
                </v-menu>
              </v-col>
            </v-row>
            <v-card-text v-else>
              Grupo de regla, sin reglas asociadas
            </v-card-text>
          </v-card>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
/**
 * Show panel form
 * @author Cristian Molina
 */
import sellersJson from "@/assets/json/sellers";
import consumersJson from "@/assets/json/consumers";
import rulesJson from "@/assets/mockups/rules";
import RuleModal from "./RuleModal.vue";

export default {
  components: { RuleModal },
  name: "panel",
  props: ["headers", "object", "isEditing", "priorityColor"],
  data: () => ({
    sellers: [],
    consumers: [],
    menu: false,
    rules: [],
    rulePriorityColor: {
      red: {
        from: 0,
        to: 0,
        color: "error",
      },
      yellow: {
        from: 0,
        to: 0,
        color: "warning",
      },
      green: {
        from: 0,
        to: 0,
        color: "success",
      },
    },
  }),
  /**
   * @description Mounted instance of component
   * @author Cristian Molina
   */
  mounted() {
    this.sellers = sellersJson;
    this.consumers = consumersJson;
    this.getRulesByGroup(this.object);
  },
  methods: {
    /**
     * @description
     * @param {any} v
     * @author Cristian Molina
     */
    getRulesByGroup(v) {
      this.rules = [];
      rulesJson.forEach((rule) => {
        if (rule.ruleGroupCode === v.ruleGroupCode) {
          this.rules.push(rule);
        }
      });
    },
    /**
     * @description
     * @param {any} v
     * @returns {any}
     * @author Cristian Molina
     */
    getPriorityColor(v) {
      if (this.priorityColor) {
        const keys = Object.keys(this.priorityColor);
        let color = "";
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (
            this.priorityColor[key].from <= v &&
            this.priorityColor[key].to >= v
          ) {
            color = this.priorityColor[key].color;
          }
        }
        if (color === "") {
          color = "accent";
        }
        return color;
      }
    },
    /**
     * @description
     * @author Cristian Molina
     */
    closePanel() {
      this.$emit("ClosePanel");
    },
  },
  watch: {
    /**
     * @description
     * @param {any} v
     * @author Cristian Molina
     */
    object(v) {
      if (v) {
        this.getRulesByGroup(v);
      }
    },
  },
};
</script>
