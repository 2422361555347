<template>
  <div>
    <v-card class="caption">
      <v-card-title>
        <span class="font-weight-regular caption">
          <v-icon class="mr-2">mdi-ruler</v-icon>Regla Código
        </span>
        <v-spacer />
        <span class="primary--text caption">
          {{ rule.ruleCode }}
        </span>
      </v-card-title>
      <v-card-text class="caption">
        <v-row align="stretch" justify="start">
          <v-col cols="12" sm="12" md="6">
            <span class="caption">Prioridad en grupo</span>
            <v-chip class="ml-2 caption" x-small>
              {{ rule.rulePriority }}
            </v-chip>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <span class="caption mr-1">Estado</span>
            <span class="font-weight-bold">
              {{ rule.status ? "Activo" : "Inactivo" }}
            </span>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <span class="caption mr-1">¿Regla de sistema?</span>
            <span class="font-weight-bold">
              {{ rule.ruleSystem ? "Si" : "No" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/**
 * Rule Modal
 * @author Cristian Molina
 */
export default { name: "modalRule", props: ["rule"] };
</script>

<style></style>
